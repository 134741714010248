import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { apiMiddleware } from 'redux-api-middleware';

import documentReducer from './documentSlice'
import accountReducer from './accountSlice'

const store = configureStore({
    reducer:{
        documents: documentReducer,
        account: accountReducer
    },
    middleware: [ apiMiddleware, ...getDefaultMiddleware()]
})

export default store;