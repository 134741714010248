
const categories = {
    "echauff": "Echauffement",
    "gb":      "Gardien",
    "edh":     "Ecole de Hand",
    "passes":  "Passes",
    "defense": "Défense",
    "attaque": "Attaque",
    "fond":    "Fondamentaux",
    "gir":     "Séance comite de Gironde",
    "comp":    "Séance completes"
}

export { categories };