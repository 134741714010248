import { createAction } from 'redux-api-middleware'
import { documentListFetched, documentListFetching, documentListFetchError } from "./documentSlice"
import { userLoginOK, userLoginFailed, userLoginSubmitting, meFailed, meSubmitting, meSucceded, userLogoutSubmitting, userLogoutSucceded, userLogoutFailed } from './accountSlice'

const base = "/api";

const documentListFetch = () => createAction({
    endpoint: `${base}/document/`,
    method: "GET",
    types: [documentListFetching.type, documentListFetched.type, documentListFetchError.type],
    credentials: "include"
})

const accountSubmitUserPassword = (data) => createAction({
    endpoint: `${base}/account/login`,
    method: "POST",
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    types: [userLoginSubmitting.type, userLoginOK.type, userLoginFailed.type],
    credentials: "include"
})

const accountLogout = (data) => createAction({
    endpoint: `${base}/account/logout`,
    method: "GET",
    types: [userLogoutSubmitting.type, userLogoutSucceded.type, userLogoutFailed.type],
    credentials: "include"
})


const accountFetchMe = () => createAction({
    endpoint: `${base}/account/me`,
    method: "GET",
    types: [meSubmitting.type, meSucceded.type, meFailed.type],
    credentials: "include"
})

export { documentListFetch, accountSubmitUserPassword, accountFetchMe, accountLogout }