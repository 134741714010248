import { createSlice } from '@reduxjs/toolkit'

import { categories } from "../common/categories";

const utilApplyFilters = (state) => {
    const safeq = state.searchQuery == null ? null : state.searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    state.documents = []
    state.all.forEach(doc => {
        const safet = doc.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        const matchTitle = state.searchQuery === null || safet.includes(safeq)
        const matchCategory = state.category === null || (state.category === "other" && !Object.keys(categories).includes(doc.category)) ||   doc.category === state.category
        if (matchTitle && matchCategory) {
            state.documents.push(doc)
        }
    })
    return state
}

const documentSlice = createSlice({
  name: 'documents',
  initialState: {
      all: null,
      documents: null,
      searchQuery: null,
      category:null,
      viewing:null
  },
  reducers: {
    documentListFetched(state, action) {
      const documentList = action.payload
      state.all = documentList
      state.documents = documentList
      state.searchQuery = null
      return state
    },
    documentListFetching(state, action) {
      state.all = 0
      return state
    },
    documentListFetchError(state, action) {
      return state
    },
    documentListSearch(state, action) {
        const q = action.payload.query 
        if (q === "")
          state.searchQuery = null
        else
          state.searchQuery = q
        return utilApplyFilters(state)
    },
    documentListFilterCategory(state, action) {
      const category = action.payload.category 
      if (category === "all") {
        state.category = null;
      } else {
        state.category = category
      }
      return utilApplyFilters(state)
    },
    documentShow(state, action) {
      state.viewing = action.payload.document
      return state
    },
    documentHide(state, action) {
      state.viewing = null
      return state
    }
  }
})


const reducer = documentSlice.reducer

export const { documentListFetched, documentListFetching, documentListFetchError, documentListSearch, documentListFilterCategory, documentShow, documentHide } = documentSlice.actions

export default reducer