import { createSlice } from '@reduxjs/toolkit'

const accountSlice = createSlice({
    name: 'account',
    initialState: {

        /*
        loginState: null,    // null, "submitting", "error"
        token: null,         // the authentication token
        userID: null,
        userName: null,
        userFlags: [],
        */

        submitting: false,
        errorMessage: null,

        me:null,                // data about logged user, when logged in
        authenticated:null      // true, false or null

    },
    reducers: {
        userLoginSubmitting: (state, action) => {
            state.submitting = true
        },
        userLoginOK: (state, action) => {
            console.log("action", action)
            const auth = action.payload.authenticated
            state.submitting = false
            if (auth) {
                state.authenticated = true
                state.errorMessage = null
            } else {
                state.authenticated = false
                state.errorMessage = "Mauvais mot de passe"
            }            
        },
        userLoginFailed: (state, action) => {
            state.submitting = false
        },

        meSubmitting: (state, action) => {
            state.authenticated = undefined
        },
        meSucceded: (state, {payload}) => {
            state.authenticated = payload.authenticated
            if (payload.authenticated) {
                state.me = payload.me
            }
        },
        meFailed: (state, action) => {

        },
        userLogoutSubmitting: (state, action) => {

        },
        userLogoutSucceded: (state, action) => {
            state.authenticated = false;
        },
        userLogoutFailed: (state, action) => {

        },
    }
})


const reducer = accountSlice.reducer

export const {userLoginOK, userLoginFailed, userLoginSubmitting, meFailed, meSubmitting, meSucceded, userLogoutSubmitting, userLogoutSucceded, userLogoutFailed } = accountSlice.actions

export default reducer