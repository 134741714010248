import React from 'react';
import FileIcon, { defaultStyles } from 'react-file-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { documentShow } from '../redux/documentSlice'
import { Link } from 'react-router-dom';

const Item = styled.div`

    display: flex;
    flex-direction: row;
    height: 32px;
    line-height: 32px;
  
    border-bottom: 1px solid #E0E0E0;
  
    margin-bottom: 8px;
    padding-bottom: 8px;
`

const Icon = styled.div`
    width: 28px;
    margin-left: 4px;
    margin-right: 4px;
`

const Title = styled.div`
    margin-left: 4px;
    margin-right: 4px;
    flex-grow: 1;
`

const DocumentItem = ({document}) => {


    function viewUrl(document) {
        return "/api/document/view/" + document.token + "/" + document.slug + "." + document.extension;

    }

    function downloadUrl(document) {
        return "/api/document/dl/" + document.token;
    }

    console.log(document);

    return (
        <Item>
            <Icon><FileIcon extension={document.extension} {...defaultStyles[document.extension]} /></Icon>
            <Title><a href={viewUrl(document)}>{document.title}</a></Title>
            <Icon><a href={downloadUrl(document)}><FontAwesomeIcon icon="download" /></a></Icon>
        </Item>
    )
}

export default DocumentItem


//  const dispatch = useDispatch()
//  <Link to={document.url} ><Icon><FontAwesomeIcon icon="eye" /></Icon></Link>
