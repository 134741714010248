import styled from "styled-components";

const HEADER_HEIGHT = 64;
const SIDERBAR_WIDTH = 256;
const PAGEHEADER_HEIGHT = 96;

const CustomHeader = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: ${HEADER_HEIGHT}px;
    background-color: red;
    line-height: ${HEADER_HEIGHT}px;
    padding-left: ${HEADER_HEIGHT / 2}px;
    background-color: #e9086f;
    color: white;
`;

const CustomSidebar = styled.div`
    position: absolute;
    top: ${HEADER_HEIGHT}px;
    left: 0px;
    width: ${SIDERBAR_WIDTH}px;
    bottom: 0px;
`;

const CustomContent = styled.div`
    position: absolute;
    top: ${HEADER_HEIGHT}px;
    left: ${SIDERBAR_WIDTH}px;
    right: 0px;
    bottom: 0px;
    background-color: #F0F0F0;
    padding: 8px;
`;

const CustomPageHeader = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: ${PAGEHEADER_HEIGHT}px;
    background-color: white;
    padding-left: ${PAGEHEADER_HEIGHT / 2}px;
    padding-right: ${PAGEHEADER_HEIGHT / 2}px;
    line-height: ${PAGEHEADER_HEIGHT}px;

    border-bottom: 1px solid #F0F0F0;
`;

const CustomPageContent= styled.div`
    position: absolute;
    top: ${PAGEHEADER_HEIGHT}px;;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    overflow-y: auto;
    padding: 8px;

`;

const CustomRow = styled.div`
    display: flex;
    align-items: center;
 
`;


const Title = styled.div`
  color: white;
  font-size: 1.4em;
  font-weight: bold;
`

const Login = styled.div`
    position: absolute;
    top: 2px;
    right: 12px;
    cursor: pointer;
`
export {CustomHeader, CustomSidebar, CustomContent, CustomPageHeader, CustomPageContent, CustomRow, Title, Login}