import React from 'react'
import styled from 'styled-components'

//import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
//import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import FileViewer from 'react-file-viewer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { documentHide } from '../redux/documentSlice';

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.7); 
  overflow-x: hidden; 
  transition: 0.5s;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
}
`


const Player = ({document}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                dispatch(documentHide())
            }
        };
        window.addEventListener('keydown', handleEsc);    
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
      }, []);

    return  (
        <Overlay>

            <FileViewer
                style={{border: "4px solid red;"}}
                fileType={document.extension}
                filePath={document.url}
                onError={<div>Error</div>}
                />

        </Overlay>
      )

/*
          errorComponent={CustomErrorComponent}
          onError={this.onError}/>
    if (document.extension == "mp4") {
        return (
            <Overlay>
                <OverlayContent>
                    <video width="320" height="240" controls="controls">
                        <source src={document.url} type="video/mp4" />
                    </video>
                </OverlayContent>
            </Overlay>
        )
    }

    if (document.extension == "pdf") {
        return (
            <Overlay>
                <OverlayContent>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                        <div style={{ height: '750px' }}>
                            <Viewer fileUrl={ document.url } />
                        </div>
                    </Worker>
                </OverlayContent>
            </Overlay>
        )
    }


    window.location = document.url;
    return <></>
    */

}

export default Player