import React from "react"
import Form from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import { Input, Button } from "antd"
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { accountSubmitUserPassword } from "../redux/api"

const LoginPage = () => {

    const dispath = useDispatch()

    const onFinish = values => {
        dispath(accountSubmitUserPassword(values))
    };

    return (
        <Form
            name="normal_login"
            style={{ maxWidth: "300px", marginLeft:'auto', marginRight:'auto', marginTop:"64px" }}
            initialValues={{ remember: true }}
            onFinish = { onFinish }
        >
            <FormItem
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Donnez votre email !',
                    },
                ]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Votre email" style={{backgroundColor: "white"}} />
            </FormItem>
            <FormItem
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Donnez votre mote de passe !',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    style={{backgroundColor: "white"}}
                />
            </FormItem>

            <FormItem>
                <Button type="primary" htmlType="submit" style={{ width: "100%"}}>
                    Connection
                </Button>        
            </FormItem>
            <FormItem>
                <a className="login-form-forgot" href="">Mot de passe oublié ?</a>
            </FormItem>
     
        </Form>
    )
}

export default LoginPage