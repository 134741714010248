import React from 'react'
import { CustomSidebar, CustomContent, CustomHeader, Title, Login } from './styled'
import { Menu, Avatar, Popover, Dropdown } from 'antd'
import DocumentList from './documentlist'
import { Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { accountLogout} from '../redux/api';



const Intranet = (props) => {

    const dispatch = useDispatch()

    return (
        <>
            <CustomHeader> 
                <Title>HBSME : Intranet</Title>
                <Login>
                    <Dropdown overlay={    
                        <Menu>
                            <Menu.Item onClick={() => dispatch(accountLogout())}>Deconnexion</Menu.Item>
                        </Menu>
                    }>
                        <Avatar icon={<UserOutlined />} />
                    </Dropdown>
                </Login>
            </CustomHeader>

            <CustomSidebar>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    style={{ height: '100%', borderRight: 0 }}
                    >
                        <Menu.Item key="1">Documenthèque</Menu.Item>
                </Menu>                
            </CustomSidebar>

            <CustomContent>
                <Route exact path="/documentheque" component={DocumentList} />
                <Route exact path="/" component={() => (<Redirect to = "/documentheque" />)} />
            </CustomContent>
        </>

    )
}

export default Intranet