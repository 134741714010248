import React from 'react';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons'

import styled from 'styled-components';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from './components/protectedroute';
import Intranet from './components/intranet';
import LoginPage from './components/loginpage';
import { useSelector, useDispatch } from 'react-redux';

import { accountFetchMe } from './redux/api';

library.add(faEye, faDownload)


function App() {

    const authenticated = useSelector(state=>state.account.authenticated)
    const dispatch = useDispatch()

    if (authenticated === null) {
        dispatch(accountFetchMe())
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/user/register" component={() => <div>register</div>} />
                <Route exact path="/user/lostpasword" component={() => <div>lost passwor</div>} />
                <ProtectedRoute login={() => <LoginPage />} component={() => <Intranet  />} />
            </Switch>
        </BrowserRouter>
    )
}

export default App;
