import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { documentListFetch } from "../redux/api"
import { documentListSearch, documentListFilterCategory} from "../redux/documentSlice"

import { Spin, Select, Input } from 'antd';

import { CustomPageHeader, CustomPageContent, CustomRow } from './styled'
import DocumentItem from './documentitem';

import { FolderOpenOutlined } from '@ant-design/icons'

import styled from 'styled-components';

import { categories } from '../common/categories';
import Player from './player';

const { Search } = Input;
const { Option } = Select;

const Section = styled.div``

const Title = styled.div`
    border: 1px solid #F0F0F0;
    font-size: 1.1em;
    padding: 16px;
    border-radius: 32px;
    margin-top: 32px;
    margin-bottom: 16px;
    box-shadow: 4px 4px 4px #E0E0E0;
    padding-left: 32px;
    color: #404040;
`

const DocumentList = (props) => {

    const slice = useSelector(state => state.documents)
    const dispatch = useDispatch()

    if (slice.all == null) {
        dispatch(documentListFetch())
    }

    function onSearch(query) {
        dispatch(documentListSearch({query}))
    }

    function onCategory(category) {
        dispatch(documentListFilterCategory({category}))
    }

    function status() {
        if (slice.documents == null) {
            return ""
        }
        
        if ((slice.searchQuery === null) && (slice.category === null)) {
            return <div>{slice.documents.length + " documents"}</div>;
        } else {
            if (slice.category === null) {
                return <div>Résultat de recherche pour <b>{slice.searchQuery}</b> : {slice.documents.length} documents</div>;
            } else {
                return <div>{slice.documents.length + " documents"}</div>;
            }
        }
    }

    const section = (catKey) =>  {
        const filtered = slice.documents.filter((doc) => doc.category == catKey)

        return filtered.length == 0 ? "" : <Section>
            <Title> <FolderOpenOutlined /> {categories[catKey]}</Title>
            { filtered.map(element => <DocumentItem document={element} key={element.id}/>) }
        </Section>
    }
    
    return (
        <>
            <CustomPageHeader>
                <CustomRow style={{height: "60%"}}>
                    <Search style={{"width": 200, marginRight:"16px"}} onSearch={value => onSearch(value)}/>
                    <Select defaultValue="all" onChange={ (cat) => {onCategory(cat) }} style={{"width": 200, marginRight:"16px"}} listHeight={1024}>
                        <Option value="all">Toutes les catégories</Option>
                        {  Object.keys(categories).map( (k) => <Option value={k}>{categories[k]}</Option> ) }
                        <Option value="other">Non classé</Option>
                    </Select>
                </CustomRow>
                <CustomRow style={{height: "20%", lineHeight:"20%", fontSize:"0.8em" }}>
                    { status() }
                </CustomRow>
            </CustomPageHeader>
            <CustomPageContent>
                <div>
                    { (slice.all === null || slice.all === 0) ? <Spin/> : Object.keys(categories).map( (catKey) =>  section(catKey) ) }
                </div>

                { slice.viewing !== null ?  <Player document={slice.viewing} /> : ""}
            </CustomPageContent>
        </>
    )
}

export default DocumentList