import React from 'react'
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const ProtectedRoute = ({component: Component, login:Login, ...rest}) => {

    const account = useSelector(state => state.account)

    return (
      <Route {...rest} render={props => {
          if (account.authenticated) {
            return <Component {...props} />;
          } else {
            return <Login />
          }
        }}
      />
    );
  };